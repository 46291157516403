import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import React, { useState } from 'react'
import { DashBoard } from './DashBoard';
import { Layout } from '../../GlobalComponent/Layout/Layout';
import TicketDashboard from './TicketDashboard/TicketDashboared';
import { BulkQrGenerate } from './bulkQrGenerate/BulkQrGenerate';
// const BulkQrGenerate = React.lazy(() => import( './bulkQrGenerate/BulkQrGenerate'));

const DashboardWrapper = () => {
    const [selectedTab, setSelectedTab] = useState(0);
    const handleTabChange = (index:number) => {
        setSelectedTab(index);
    };
    return (
        <Layout>
            <Tabs  index={selectedTab} onChange={handleTabChange} variant="soft-rounded" colorScheme="green">
                <TabList>
                    <Tab>Beneficiary Dashboard Details</Tab>
                    <Tab>Ticket Dashboard Details</Tab>
                    <Tab>Bulk Qr Generate</Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                     <DashBoard/>
                    </TabPanel>
                    <TabPanel>
                     <TicketDashboard/>
                    </TabPanel>
                    <TabPanel>
                    <React.Suspense fallback={<div>Loading...</div>}>
                    {selectedTab === 2 && <BulkQrGenerate />}
                    </React.Suspense>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Layout>

    )
}

export default DashboardWrapper
