import React, { useState } from 'react';
import { RiDashboardFill } from "react-icons/ri";
import { 
  Box,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  Text,
  VStack,
} from '@chakra-ui/react';
import { createBulkQr } from '../../../Http/Index';

interface Props {
  onSuccess: () => void;
}

export function DocumentGenerator({ onSuccess }: Props) {
  const [count, setCount] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string>('');

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      const payload={
        count
      }
      await createBulkQr(payload);
      onSuccess();
    } catch (err) {
      setError(err instanceof Error ? err.message : 'An error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box bg="white" p={6} borderRadius="lg" boxShadow="md">
      <Heading as="h2" size="lg" mb={4}>Bulk QR Generator</Heading>
      <form onSubmit={handleSubmit}>
        <VStack spacing={4}>
          <FormControl>
            <FormLabel>Number of Documents</FormLabel>
            <Input
              type="number"
              min={1}
              value={count}
              onChange={(e) => setCount(Math.max(1, parseInt(e.target.value) || 1))}
            />
          </FormControl>
          
          {error && (
            <Text color="red.500" fontSize="sm">{error}</Text>
          )}
          
          <Button
            type="submit"
            isLoading={loading}
            loadingText="Generating..."
            colorScheme="blue"
            width="full"
            leftIcon={loading ? undefined :    <Icon as={RiDashboardFill} boxSize={8} />}
          >
            Generate Documents
          </Button>
        </VStack>
      </form>
    </Box>
  );
}