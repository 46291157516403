import  {  useState } from 'react';
// import { FileText } from 'lucide-react';
import { 
  Box,
  Container,
  Grid,
  Heading,
  Icon,
  Text,
  VStack,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import { RiDashboardFill } from "react-icons/ri";
import { DocumentGenerator } from './DocumentGenerator';
import { ExcelDownloader } from './ExcelDownloader';
import ActiveQrCount from './ActiveQrcode';

function QRBackground() {
  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      right={0}
      bottom={0}
      zIndex={-1}
      opacity={0.05}
      backgroundImage={`
        radial-gradient(circle at 0 0, black 1px, transparent 1px),
        radial-gradient(circle at 50% 50%, black 1px, transparent 1px)
      `}
      backgroundSize="40px 40px"
      backgroundPosition="0 0, 20px 20px"
    />
  );
}

export function BulkQrGenerate() {
  const [showSuccess, setShowSuccess] = useState(false);
  const [isAvailableActiveQr, SetIsAvailableActiveQr] = useState(false);


  const handleSuccess = () => {
    setShowSuccess(true);
    setTimeout(() => setShowSuccess(false), 3000);
  };


 

  return (
    <Box minH="100vh" position="relative">
      <QRBackground />
      <Container maxW="4xl" py={12}>
        <VStack spacing={8}>
          {/* Header */}
          <VStack spacing={4}>
            <Box color="blue.500">
              <Icon as={RiDashboardFill} boxSize={12} />
            </Box>
            <Heading
              as="h1"
              size="2xl"
              textAlign="center"
              bgGradient="linear(to-r, blue.500, blue.600)"
              bgClip="text"
            >
              Document Generator
            </Heading>
            <Text fontSize="xl" color="gray.600" textAlign="center">
              Generate documents and export them to Excel
            </Text>
          </VStack>

          {/* Success Message */}
          {showSuccess && (
            <Alert status="success" borderRadius="md">
              <AlertIcon />
              Documents generated successfully! You can now download them as Excel.
            </Alert>
          )}
          <ActiveQrCount SetIsAvailableActiveQr={SetIsAvailableActiveQr}/>
        <Grid templateColumns={{ base: '1fr', md: 'repeat(2, 1fr)' }} gap={6} width="100%">
            <DocumentGenerator onSuccess={handleSuccess} />
            <ExcelDownloader isAvailableActiveQr={isAvailableActiveQr} />
          </Grid>
    
        </VStack>
      </Container>
    </Box>
  );
}

export default QRBackground;