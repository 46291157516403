import  { useState } from 'react';
import { RiDashboardFill } from "react-icons/ri";
import {
    Box,
    Button,
    Heading,
    Icon,
    Text,
} from '@chakra-ui/react';
import { downloadQr } from '../../../Http/Index';
// import { downloadExcel } from '../services/api';

export function ExcelDownloader({isAvailableActiveQr}:any) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>('');

    const handleDownload = async () => {
        setLoading(true);
        setError('');

        try {
            const indianDate = new Date().toLocaleString("en-IN", {
                timeZone: "Asia/Kolkata",
                day: "2-digit",
                month: "2-digit",
                year: "numeric",
                hour: "2-digit",
                minute: "2-digit",
                second: "2-digit",
                hour12: false, // Use 24-hour format
            }).replace(/[/:,]/g, "_");
        const response=await downloadQr();
        const blob = new Blob([response.data], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
        const link = document.createElement("a")
        link.href = URL.createObjectURL(blob);
        link.download = `Bulk_QR_Codes-${indianDate}.xlsx`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box bg="white" p={6} borderRadius="lg" boxShadow="md">
            <Box gap={"6.2rem"} justifyItems={"flex-end"} alignItems={"stretch"} display={"flex"} flexDirection={"column"} >
                <Box>
                    <Heading as="h2" size="lg">Download Documents</Heading>
                </Box>
                <Box>
                    <Button
                        w={"100%"}
                        onClick={handleDownload}
                        isLoading={loading}
                        loadingText="Downloading..."
                        colorScheme="green"
                        isDisabled={!isAvailableActiveQr}
                        leftIcon={loading ? undefined : <Icon as={RiDashboardFill} boxSize={8} />}
                    >
                        Download Excel
                    </Button>
                </Box>
                {error && (
                    <Text color="red.500" fontSize="sm">{error}</Text>
                )}
            </Box>
        </Box>
    );
}