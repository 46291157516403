import {useEffect, useState } from 'react';
import { getInActiveQrCount } from '../../../Http/Index';

const ActiveQrCount = ({SetIsAvailableActiveQr}:any) => {
  const [count, setCount] = useState(0);
  const [errorShown, setErrorShown] = useState(false); 

  useEffect(() => {
    const eventSourceOptions = {
      withCredentials: true, // Include credentials (cookies) with the request
    };
    // console.log('getInActiveQrCount:', getInActiveQrCount);
    const eventSource = new EventSource(getInActiveQrCount,eventSourceOptions);
    // console.log('eventSource:', eventSource);
     // eslint-disable-next-line 
    eventSource.onmessage = (event) => {
      if(!errorShown){
        setErrorShown(true);
      }
        if(JSON.parse(event.data).data>0){
            SetIsAvailableActiveQr(true);
        }
      setCount(JSON.parse(event.data).data);
          //eslint-disable-next-line
    };

    eventSource.onerror = (error) => {  
        console.error('EventSource failed:', error);
        if (!errorShown) {
          alert("Something went wrong with the active QR count.");
          setErrorShown(true); // Ensure the alert shows only once
        } else{
            setErrorShown(false);
            SetIsAvailableActiveQr(true);
        }
    }

    return () => {
      eventSource.close();
    };
     // eslint-disable-next-line 
  }, []);
  return <div>
    <div>Active QR Count: {count}</div>
    {!errorShown && <div style={{color:"red"}}>Some Technical Issue in getting active qr count Still you can Generate & Download ✌️</div>}
  </div>;
};

export default ActiveQrCount;
